body,
html {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
nav {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e1e1e1;
  padding: 1rem;
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: white;
  top: 0px;
}
nav ul {
  display: flex;
  list-style: none;
  gap: 2rem;
  color: #666;
  font-size: 16px;
  justify-content: flex-end;
  padding-right: 4rem;
  cursor: pointer;
}
nav li:hover {
  color: #397aff;
}
nav li a {
  text-decoration: none;
  color: inherit;
}
main {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  gap: 1rem;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
}
.intro {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  gap: 1rem;
  align-items: center;
}

.picture img {
  width: 25rem;
}

.intro-text {
  width: 40%;
}

.intro-text h2 {
  color: #666;
  text-align: justify;
}

.profile-desc {
  max-width: 100%;
  margin-bottom: 0px;
  font-family: "Helvetica Now", sans-serif;
  color: #666;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.14px;
}

.section-about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid #e1e1e1;
  width: 75%;
}
.about-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10rem;
  width: 100%;
}
.about-wrapper a {
  text-decoration: none;
  color: #397aff;
}

.about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.section-mystory {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
}

.mystory-text {
  width: 50%;
}

.mystory-text h3 {
  overflow: visible;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 15px;
  order: -1;
  color: #666;
  font-weight: 500;
}

.mystory-text a {
  text-decoration: none;
  color: #397aff;
}
.mystory-text ul li {
  padding: 0.2rem;
}

.work-experience {
  display: flex;
  flex-direction: column;
}

.work-experience h3 {
  overflow: visible;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 15px;

  order: -1;
  color: #666;
  font-weight: 500;
}
.company-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.date-range {
  display: block;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #999;
  font-size: 14px;
  font-weight: 400;
}
.company-name {
  display: inline-block;
  margin-bottom: 2px;
  font-weight: 700 !important;
}
.company-location {
  display: inline;
  margin-left: 20px;
  padding-left: 0px;
  color: #666 !important;
}
.body-paragraph {
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  /* -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1; */
  color: #272727;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: left;
}

.company {
  display: flex;
  flex-direction: row;
  gap: 0.1rem;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.section-contact {
  justify-content: center;
  width: 75%;
  gap: 2rem;
}
.contact {
  gap: 2rem;
}
.contact p {
  max-width: 100%;
  margin-bottom: 0px;
  font-family: "Helvetica Now", sans-serif;
  color: #666;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.14px;
}
.phone-booth img {
  border-radius: 10px;
}

.contact-btn {
  display: flex;
  width: max-content;
  padding: 18px 25px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #272727;
  transition: color 400ms ease, background-color 400ms ease,
    box-shadow 400ms ease;
  font-family: "Helvetica Now", sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.footer {
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e1e1e1;
  padding: 1rem;
  font-family: "Helvetica Now", sans-serif;
  color: #666;
  margin-top: 0.5rem;
}
.section-skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
}

.section-skills h3 {
  overflow: visible;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 15px;
  order: -1;
  color: #666;
  font-weight: 500;
}
.skill-wrapper {
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}
.skills {
  display: flex;
  flex-direction: column;
  background-color: rgb(243, 243, 243);
  width: 111px;
  height: 111px;
  min-width: 111px;
  border-radius: 0.75rem;
  align-items: center;
  justify-content: center;
  font-family: averta_stdregular, Helvetica Neue, Helvetica;
}
.skills p {
  font-size: 0.875rem;
  color: #606f7b;
  margin: 0.2rem;
}

.youtube-iframe {
  border-radius: 5px;
  margin-bottom: 16px;
  margin-top: 16px;
  width: 800px;
  height: 450px;
}

@media only screen and (max-width: 600px) {
  main {
    padding: 2rem;
  }
  .intro {
    flex-direction: column-reverse;
  }
  .intro-text {
    text-align: center;
    width: 100%;
  }
  .intro-text h1 {
    font-size: 1.8rem;
  }
  .section-mystory {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .mystory-text,
  .section-skills {
    width: 100%;
  }
  .phone-booth {
    display: none;
  }
  .section-contact {
    text-align: center;
    width: 100%;
    justify-content: center;
  }
  nav ul {
    justify-content: center;
  }
  .youtube-iframe {
    width: 265px;
    height: 150px;
  }
}
